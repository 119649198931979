import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { useTranslate } from "react-admin";
import NotificationHelper from "../../helpers/NotificationHelper";
import moment from "moment";
import AppelCotisationModalButton from "../components/AppelCotisationModalButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import { Routes } from "../routes";
import ReservationWebController from "../../controllers/ReservationWebController";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        margin: theme.spacing(2, 0),
        alignItems: "center",
    },
    content: {
        flexGrow: 1,
    },
    actions: {
        marginLeft: theme.spacing(1),
        flexGrow: 0,
        flexShrink: 0,
    },
    date: {
        color: theme.palette.secondary.dark,
    },
    text: {
        color: theme.palette.secondary.dark,
    },
}));
/**
 *
 * @param {Object} props
 * @param {NotificationHelper} props.notification
 */
const NotificationListItem = ({ notification, className, ...props }) => {
    const classes = useStyles();
    const { createdAt, text } = notification;
    const translate = useTranslate();
    return (
        <div className={classnames(className, classes.root)} {...props}>
            <div className={classes.content}>
                <Typography variant="h6" component="p" className={classes.text}>
                    {text}
                </Typography>
            </div>
            {notification.type === "appelCotisation" &&
            notification.payload &&
            notification.payload.idAppelCotisation ? (
                <div className={classes.actions}>
                    <AppelCotisationModalButton
                        idAppelCotisation={
                            notification.payload.idAppelCotisation
                        }
                    ></AppelCotisationModalButton>
                </div>
            ) : null}
            {notification.type === "reservationWeb" &&
            notification.payload &&
            notification.payload.idReservationWeb ? (
                <div className={classes.actions}>
                    <ReservationWebController
                        id={notification.payload.idReservationWeb}
                    >
                        {({ data: reservation, loading, error }) =>
                            reservation ? (
                                <Button
                                    component={Link}
                                    variant="contained"
                                    color="secondary"
                                    to={generatePath(Routes.chapitreReserver, {
                                        id: reservation.chapitre,
                                        slug: "-",
                                        idReservation: reservation.id,
                                        action: "edit",
                                    })}
                                >
                                    {translate("Voir ma réservation")}
                                </Button>
                            ) : null
                        }
                    </ReservationWebController>
                </div>
            ) : null}
        </div>
    );
};

export default NotificationListItem;
